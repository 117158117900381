.howToPlay, .howToPlayActive {
    color: #FFC700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    flex-wrap: wrap;
    padding: 2.5rem 1.5rem;
}

.howToPlay svg, .howToPlayActive svg {
    margin-right: 0.75rem;
}

.howToPlay {
    border: 2px solid black;
    margin-top: 0.75rem;
}

.howToPlayActive {
    border: 2px solid white;
    border-radius: 10px;
    margin-top: 1.75rem;
    margin-bottom: 2.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}

.rulesContent {
    display: none;
    margin-top: 1.5rem;
}

.rulesContent p {
    color: white;
    font-size: 16px;
    text-align: left;
}

.rulesContent a {
    font-size: 14px;
    color: white;
    text-decoration: none;
}

.howToPlayActive .rulesContent {
    display: block;
}
