/**
 *  GLOBAL
 */

 
.App {
  text-align: center;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 30px;
}

.App-contain {
  max-width: 375px;
  width: 90%;
  margin: 0 auto;
}

.App-footer {
  margin-top: auto;
  padding: 20px 0;
}

.App-footer a {
  text-decoration: none;
}

.App-footer img {
  margin-bottom: 10px;
}

.App-footer span {
  display: block;
  text-align: center;
  color: #ffffff;
  font-family: 'Aeonik';
  font-weight: 500;
}

/**
*   FONTS
*/
@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
      url('./fonts/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
      url('./fonts/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk-Light.woff2') format('woff2'),
      url('./fonts/SpaceGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk-Medium.woff2') format('woff2'),
      url('./fonts/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
      url('./fonts/SpaceGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./fonts/Aeonik-Regular.woff2') format('woff2'),
      url('./fonts/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./fonts/Aeonik-Bold.woff2') format('woff2'),
      url('./fonts/Aeonik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-SemiBold.woff2') format('woff2'),
      url('./fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.font--space-grotesk {
  font-family: 'Space Grotesk';
}

.font--space-grotesk-medium {
  font-family: 'Space Grotesk';
  font-weight: 500;
}

.font--space-grotesk-bold {
  font-family: 'Space Grotesk';
  font-weight: 700;
}

.font--aeonik {
  font-family: 'Aeonik';
}

.font--aeonik-bold {
  font-family: 'Aeonik';
  font-weight: 700;
}

.font--inter-semibold {
  font-family: 'Inter';
}

/** 
 *  FORM
 */

.form {
  margin: 3.5rem auto 0;
  max-width: 331px;
  width: 100%;
}

.form input[type="text"], .form input[type="email"] {
  border: solid 2px white;
  border-radius: 0;
  background-color: black;
  width: 100%;
  height: 50px;
  padding: 13px 25px;
  margin-bottom: 16px;
  color: white;
  font-size: 16px;
  font-family: 'Aeonik';
  -webkit-appearance: none;
  box-sizing: border-box;
}

.form .submitWrapper, .buttonWrapper {
  background-image: url('btn-bg.svg');
  margin-top: 2rem;
  width: calc(100% + 0.5rem);
  padding-bottom: 0.5rem;
  padding-left: 0.25rem;
}

.form .submitWrapper input[type="button"], .button {
  border: solid 2px white;
  border-radius: 0;
  background-color: white;
  text-transform: uppercase;
  padding: 27px 13px;
  margin: -0.5rem 0 0 -1rem;
  text-align: center;
  width: calc(98% + 0.5rem);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Inter';
  -webkit-appearance: none;
}

.form .submitWrapper input[type="button"]:hover, .button {
  opacity: 0.95;
}

.form .form-error {
  color: red;
  margin-bottom: 1rem;
  font-family: 'Aeonik';
}

/** 
 *  LETTERS
 */

.letters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.letters .letter {
  display: block;
  width: 54px;
  height: 54px;
  border: 1px solid rgba(255, 255, 255, 0.58);
}

.letters .letter.found {
  background-image: url('tick.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: #FFC700;
}

.letters.shuffledWord .letter {
  background-color: #FFC700;
  color: black;
  font-family: 'Aeonik';
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.letters.guess .letter {
  background-color: black;
  color: white;
  font-family: 'Aeonik';
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  -webkit-appearance: none;
  border-radius: 0;
  outline: none;
  padding: 0;
}

/** 
 *  STANDS
 */

.stands {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.stands .stand {
  width: 49%;
  margin-bottom: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  position: relative;
  font-family: 'Aeonik';
  max-height: 160px;
  height: 38.1vw;
}

.stands .stand.captured .qrcode {
  opacity: 0;
}

.stands .stand.captured:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-image: url('tick.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: #FFC700;
  background-size: 19px 14px;
}

.stands .stand > img {
  width: 80%;
}

/**
 *  SCANNER
 */

.scannerWrap {
  position: fixed;
  height: 70vh;
  width: 100%;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: -70vh;
  left: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scannerWrap.showScanner {
  bottom: 0;
}

.scannerWrap .close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.scanner {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  position: relative;
  padding-bottom: 400px;
  padding-bottom: 100vw;
}

@media (min-width: 400px) {
  .scanner {
    padding-bottom: 400px;
  }
}

.scanner video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none !important;
}

/** 
 *  GUESS
 */

.messages {
  color: white;
  font-size: 18px;
  margin-top: 5rem;
}

#guessContainer input[type="text"]:disabled {
  background-color:#14D74A
}

.buttonWrapper {
  width: 98%;
}

.buttonWrapper .button {
  margin-left: -0.5rem;
  display: block;
  text-decoration: none;
  color: black;
}


/**
*   ANIMATIONS
*/
.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.grow { 
  transition: all .2s ease-in-out; 
}
.grow:hover { 
  transform: scale(1.1); 
}